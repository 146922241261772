import axios from "axios";
export default({
    state: {
      locations: [],
      productStatuses: [],
      categories: [],
      userTypes: []
    },
    mutations: {
      SET_LOCATIONS(state, payload) {
        state.locations = payload;
      },
      SET_PROD_STATUSES(state, payload) {
        state.productStatuses = payload;
      },
      SET_CATEGORIES(state, payload){
        state.categories = payload;
      },
      SET_USER_TYPES(state, payload){
        state.userTypes = payload;
      }
    },
    actions: {
      setLocation({dispatch}, payload) {
        return axios.post(process.env.VUE_APP_API_URL+"/location",payload,
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' },
        }
        )
        .then((res) => {
            console.log(res.data)
            dispatch("getLocations");
            return res.data;
        })
      },  
      getLocations({commit}) {
        return axios.get(process.env.VUE_APP_API_URL+"/location/list",
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' },
        }
        )
        .then((res) => {
            console.log(res.data)
            commit("SET_LOCATIONS", res.data);
            return res.data;
        })
      },
      setProductStatus({dispatch}, payload) {
        return axios.post(process.env.VUE_APP_API_URL+"/product/status",payload,
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' },
        }
        )
        .then((res) => {
            console.log(res.data)
            dispatch("getProductStatuses");
            return res.data;
        })
      },
      getProductStatuses({commit}) {
        return axios.get(process.env.VUE_APP_API_URL+"/product/status/list",
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' },
        }
        )
        .then((res) => {
            console.log(res.data)
            commit("SET_PROD_STATUSES", res.data);
            return res.data;
        })
      },
      setCategory({dispatch}, payload) {
        return axios.post(process.env.VUE_APP_API_URL+"/category",payload,
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' },
        }
        )
        .then((res) => {
            console.log(res.data)
            dispatch("getCategories");
            return res.data;
        })
      },
      getCategories({commit}){
        return axios.get(process.env.VUE_APP_API_URL+"/category/list",{
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' },
       })
       .then((res)=>{
         console.log(res.data)
         commit("SET_CATEGORIES",res.data);
         return res.data;
       })
      },
      deleteCategory(_,payload){
        console.log(payload);
        return axios.delete(process.env.VUE_APP_API_URL+"/category?id="+payload.id,
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        }).then((res) => {
          return res.data;
        })
      },
      deleteLocation(_,payload){
        console.log(payload);
        return axios.delete(process.env.VUE_APP_API_URL+"/location?id="+payload.id,
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        }).then((res) => {
          return res.data;
        })
      },
      deleteProductStatus(_,payload){
        console.log(payload);
        return axios.delete(process.env.VUE_APP_API_URL+"/product/status?id="+payload.id,
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        }).then((res) => {
          return res.data;
        })
      },
      setUserType({dispatch}, payload) {
        return axios.post(process.env.VUE_APP_API_URL+"/user/type",payload,
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' },
        }
        )
        .then((res) => {
            console.log(res.data)
            dispatch("getUserTypes");
            return res.data;
        })
      },
      getUserTypes({commit}){
        return axios.get(process.env.VUE_APP_API_URL+"/user/type/list",{
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' },
       })
       .then((res)=>{
         console.log(res.data)
         commit("SET_USER_TYPES",res.data);
         return res.data;
       })
      }
    },
    getters: {},
  });