import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import store from "./store/Store";
import router from "./router/Router";
import "./assets/scss/style.scss";
import App from "./App.vue";
import VueFeather from "vue-feather";
import InstantSearch from "vue-instantsearch";
// i18n
import i18n from './i18n/i18n.js'
import "vue-hljs/dist/style.css";
import moment from "moment";
import VueMoment from "vue-moment";
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
Vue.use(VueFeather);

Vue.config.productionTip = false;

import vueHljs from "vue-hljs";
import hljs from "highlight.js";
import axios from 'axios';
import VueSanctum from 'vue-sanctum';
import VCalendar from 'v-calendar';


// Create your axios instance
const axiosInstance = axios.create({
    baseURL: 'https://api.proctor-i.com/login',
    withCredentials: true
}) 


//use
Vue.use(VueLodash, { lodash: lodash });
Vue.use(VueMoment, {
    moment
});
Vue.use(vueHljs, { hljs });
Vue.use(InstantSearch);
// pass in the store to activate the vuex sanctum module.
Vue.use(VueSanctum, {
    axios: axiosInstance,
    store,
});

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
    componentPrefix: 'vc'  // Use <vc-calendar /> instead of <v-calendar />
});
new Vue({ store, router,i18n, render: (h) => h(App) }).$mount("#app");
