<template>
  <div id="main-wrapper">
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: 'App',
  components: {
    
  },
  computed:{
    ...mapState({
      isAuthenticated: state => state.auth.isAuthenticated
    })
  },
  mounted(){
    if(localStorage.getItem("loggedIn")){
      this.saveAuthenticatedUserInfo();
    }
    else{
      if(this.$route.name){
        console.log(this.$route.name, "Here");
        this.$router.push({name:"Login"});
      }
    }
  },
  methods: {
    ...mapActions(["saveSignInInfo"]),
    saveAuthenticatedUserInfo(){
      this.saveSignInInfo({
        token: localStorage.getItem("authToken"),
        email: localStorage.getItem("email"),
        id: localStorage.getItem("userid"),
        type: localStorage.getItem("usertype")
      });
    }
  }
}
</script>

<style>

</style>
