import axios from "axios";
export default ({
  state: {
    resources: []
  },
  mutations: {
    SET_RESOURCES(state, payload) {
      state.resources = payload;
    }
  },
  actions: {
    getResources({ commit }) {
      return axios.get(process.env.VUE_APP_API_URL+"/resource/list",
        {
          headers: { 'Authorization': "Bearer " + localStorage.getItem('authToken'), 'Accept': 'application/json' }
        }
      )
        .then((res) => {
          //console.log(res.data)
          commit("SET_RESOURCES", res.data.resources);
          return res.data.resources;
        })
    },
    getResourcesByUser({ commit }, payload) {
      return axios.get(process.env.VUE_APP_API_URL+"/resource/list/user?user_id=" + payload.id,
        {
          headers: { 'Authorization': "Bearer " + localStorage.getItem('authToken'), 'Accept': 'application/json' }
        }
      )
        .then((res) => {
          //console.log(res.data)
          commit("SET_RESOURCES", res.data.resources);
          return res.data.resources;
        })
    },
    getResource(_, payload) {
      return axios.get(process.env.VUE_APP_API_URL+"/resource?id="+payload.id,
        {
          headers: { 'Authorization': "Bearer " + localStorage.getItem('authToken'), 'Accept': 'application/json' }
        }
      )
        .then((res) => {
          //console.log(res.data)
          return res.data.resources;
        })
    },
    deleteResource(_, payload) {
      console.log(payload);
      return axios.delete(process.env.VUE_APP_API_URL+"/resource?id="+payload.id,
        {
          headers: { 'Authorization': "Bearer " + localStorage.getItem('authToken'), 'Accept': 'application/json' }
        }).then((res) => {
          return res.data;
        })

    },
    saveResource({ dispatch }, payload) {
      return axios.post(process.env.VUE_APP_API_URL+"/resource",
        payload,
        {
          headers: { 'Authorization': "Bearer " + localStorage.getItem('authToken'), 'Accept': 'application/json' }
        })
        .then((res) => {
          dispatch("getResources", payload.id);
          console.log(res);
          return res;
        })
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            //console.log(error.response.data);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            return error.response;
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }

        });
    },
    updateResource( {dispatch} , payload) {
      return axios.patch(process.env.VUE_APP_API_URL+"/resource",
      payload,
      { 
        headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
      })
      .then((res) => {
        console.log(res);
        dispatch("getResources");
        return res;
      })
      .catch((error) => {
        return error.response
      })
    }
  },
  getters: {},
});