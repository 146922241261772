import axios from "axios";
export default({
    state: {},
    mutations: {},
    actions: {  
      getInspectionJobs() {
        return axios.get(process.env.VUE_APP_API_URL+"/inspection/job/list",
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        }
        )
        .then((res) => {
            console.log(res.data)
            return res.data;
        })
      },
      getInspectionJob(_, payload) {
        return axios.get(process.env.VUE_APP_API_URL+"/inspection/job?id="+payload.id,
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        }
        )
        .then((res) => {
            console.log(res.data)
            return res.data;
        })
      },
      saveInspectionJob( _ , payload) {
        return axios.post(process.env.VUE_APP_API_URL+"/inspection/job",
        payload,
        { 
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        })
        .then((res) => {
          console.log(res);
          return res;
        })
        .catch((error) => {
          return error.response
        })
      },
      updateInspectionJob( _ , payload) {
        return axios.patch(process.env.VUE_APP_API_URL+"/inspection/job",
        payload,
        { 
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        })
        .then((res) => {
          console.log(res);
          return res;
        })
        .catch((error) => {
          return error.response
        })
      }
    },
    getters: {},
  });