import axios from "axios";
export default({
    state: {},
    mutations: {},
    actions: {  
      saveFile( _ , payload) {
        return axios.post(process.env.VUE_APP_API_URL+"/media/upload",
        payload,
        { 
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        })
        .then((res) => {
          return res;
        })
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            //console.log(error.response.data);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            return error.response;
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }

        });
      }
    },
    getters: {},
  });