import axios from "axios";
export default({
    state: {
        jobs: [],
        jobMsgs: [],
        currentjob: null
    },
    mutations: {
      SET_jobs(state, payload) {
        state.jobs = payload;
      }  
    },
    actions: {  
      getjobs({ commit }) {
        return axios.get(process.env.VUE_APP_API_URL+"/resource/job/list",
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        }
        )
        .then((res) => {
            console.log(res.data)
            commit("SET_jobs", res.data);
            return res.data;
        })
      },
      deletejob(_,payload){
        console.log(payload);
        return axios.delete(process.env.VUE_APP_API_URL+"/resource/job?id="+payload.id,
        payload, 
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        }).then((res) => {
          return res.data;
        })
        
      },
      getjobsByUser({ commit }, payload) {
        return axios.get(process.env.VUE_APP_API_URL+"/resource/job/user?user_id="+payload.userid,
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        }
        )
        .then((res) => {
            console.log(res.data)
            commit("SET_jobs", res.data);
            return res.data;
        })
      },
      getjob({ commit }, payload) {
        return axios.get(process.env.VUE_APP_API_URL+"/resource/job/resource?resource_id="+payload.id,
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        }
        )
        .then((res) => {
            console.log(res.data)
            commit("SET_job", res.data);
            return res.data;
        })
      },
      savejob( _ , payload) {
        return axios.post(process.env.VUE_APP_API_URL+"/resource/job",
        payload,
        { 
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        })
        .then((res) => {
            console.log(res);
            return res;
        })
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            //console.log(error.response.data);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            return error.response;
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
      
        });
      }
    },
    getters: {
      jobById: state => payload => state.jobs.find(o => o.id === payload.id),
    },
  });