import axios from "axios";
export default({
    state: {
        orders: [],
        orderMsgs: [],
        currentOrder: null
    },
    mutations: {
      SET_ORDERS(state, payload) {
        state.orders = payload;
      },
      SET_MESSAGES(state, payload){
        //state.orderMsgs.push(payload);
        state.orderMsgs = payload;
      }  
    },
    actions: {  
      getOrders({ commit }) {
        return axios.get(process.env.VUE_APP_API_URL+"/resource/order/list",
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        }
        )
        .then((res) => {
            console.log(res.data)
            commit("SET_ORDERS", res.data);
            return res.data;
        })
      },
      getOrdersByUser({ commit }, payload) {
        return axios.get(process.env.VUE_APP_API_URL+"/resource/order/user?user_id="+payload.userid,
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        }
        )
        .then((res) => {
            console.log(res.data)
            commit("SET_ORDERS", res.data);
            return res.data;
        })
      },
      getOrdersByResource(_, payload) {
        return axios.get(process.env.VUE_APP_API_URL+"/resource/order/resource?resource_id="+payload.id,
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        }
        )
        .then((res) => {
            console.log(res.data)
            //commit("SET_ORDERS", res.data);
            return res.data;
        })
      },
      deleteOrder(_,payload){
        return axios.delete(process.env.VUE_APP_API_URL+"/resource/order?id="+payload.id,
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        }).then((res) => {
          return res.data;
        })
      },
      getOrder(_, payload) {
        return axios.get(process.env.VUE_APP_API_URL+"/resource/order?id="+payload.id,
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        }
        )
        .then((res) => {
            console.log(res.data);
            return res.data;
        })
      },
      saveOrder( _ , payload) {
        return axios.post(process.env.VUE_APP_API_URL+"/resource/order",
        payload,
        { 
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        })
        .then((res) => {
            console.log(res);
            return res;
        })
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            //console.log(error.response.data);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            return error.response;
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
      
        });
      },
      changeOrder( _ , payload) {
        return axios.patch(process.env.VUE_APP_API_URL+"/resource/order",
        payload,
        { 
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        })
        .then((res) => {
            console.log(res);
            return res;
        })
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            //console.log(error.response.data);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            return error.response;
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
        });
      },
      saveMessage({dispatch}, payload) {
        return axios.post(process.env.VUE_APP_API_URL+"/message",
        payload,
        { 
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        })
        .then((res) => {
            console.log(res);
            dispatch("getMessagesByOrder", {id: payload.entity_id});
            return res;
        })
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            //console.log(error.response.data);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            return error.response;
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
      
        });
      },
      getMessagesByOrder({commit}, payload) {
        return axios.get(process.env.VUE_APP_API_URL+"/message/entity?entity_id="+payload.id,
        { 
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        })
        .then((res) => {
            console.log(res);
            commit("SET_MESSAGES", res.data);
            return res.data;
        });
      },
      getAvailability(_, payload) {
        return axios.get(process.env.VUE_APP_API_URL+"/calendar/template/validity?resource_id="+payload.resource_id+"&start_date="+payload.start_date+"&end_date="+payload.end_date,
        { 
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        })
        .then((res) => {
            console.log(res);
            return res.data;
        })
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            //console.log(error.response.data);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            return error.response;
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
      
        });
      }
    },
    getters: {
      orderById: state => payload => state.orders.find(o => o.id === payload.id),
      msgById: state => payload => state.orderMsgs.find(m => m.order === payload.id),
    },
  });