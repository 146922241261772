import axios from "axios";
export default({
    state: {
      isAuthenticated: false,
      authToken: null,
      authEmail: null,
      authId: null,
      authUserType: null,
      users: []
    },
    mutations: {
      SET_AUTH(state, payload) {
        if(payload.token){
          state.isAuthenticated = true;
          state.authToken = payload.token;
          state.authEmail = payload.email;
          state.authId = payload.id;
          state.authUserType = payload.type;
          localStorage.setItem("loggedIn", true);
          localStorage.setItem("authToken", payload.token);
          localStorage.setItem("email", payload.email);
          localStorage.setItem("userid", payload.id);
          localStorage.setItem("usertype", payload.type);
        }
        else{
          state.isAuthenticated = false;
          state.authToken = null;
          state.authEmail = null;
          state.authId = null;
          state.authUserType = null;
          localStorage.removeItem("loggedIn");
          localStorage.removeItem("authToken");
          localStorage.removeItem("email");
          localStorage.removeItem("userid");
          localStorage.removeItem("usertype");
        }
      },
      SET_USERS(state, payload){
        state.users = payload;
      }
    },
    actions: {  
      registerMe(_, payload) {
        return axios.post(process.env.VUE_APP_API_URL+"/signup",
        payload,
        { 
            header: { 'Accept': 'application/json' }
        })
        .then((res) => {
            console.log(res)
            return res;
        })
        .catch((error) => {
          return error.response
        })
      },
      signInMe( {dispatch} , payload) {
        let user = {};
        return axios.post(process.env.VUE_APP_API_URL+"/login",
        payload,
        { 
            header: { 'Accept': 'application/json' }
        })
        .then((res) => {
            console.log(res.data);
            user.token = res.data.token;
            user.email = payload.email;
            dispatch("getLoggedUser", user);
            return res;
        })
        .catch((error) => {
          return error.response
        })
      },
      getLoggedUser( {commit}, payload) {
        let user = {};
        return axios.get(process.env.VUE_APP_API_URL+"/user/login",
        { 
          headers: { 'Authorization': "Bearer " +payload.token, 'Accept': 'application/json' }
        })
        .then((res) => {
          console.log(res.data);
          user.token = payload.token;
          user.email = payload.email;
          if(parseInt(res.data.user_type) === 6){
            user.id = res.data.phone;
          }
          else{
            user.id = res.data.id;
          }
          user.type = res.data.user_type;
          commit("SET_AUTH", user);
        })
      },
      getUsers( {commit} ) {
        return axios.get(process.env.VUE_APP_API_URL+"/user/list",
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        })
        .then((res) => {
            commit("SET_USERS", res.data);
            return res.data;
        })
      },
      getUser( _, payload ) {
        return axios.get(process.env.VUE_APP_API_URL+"/user?id="+payload.id,
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        })
        .then((res) => {
            return res.data.user;
        })
      },
      saveSignInInfo( {commit} , payload) {
        commit("SET_AUTH", payload);
      },
      signOutMe( {commit} ) {
        commit("SET_AUTH", { token: null })
      },
      saveProfile(_, payload) {
        return axios.post(process.env.VUE_APP_API_URL+"/profile",
        payload,
        { 
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        })
        .then((res) => {
            console.log(res)
            return res;
        })
        .catch((error) => {
          return error.response
        })
      },
      deleteUser(_,payload){
        return axios.delete(process.env.VUE_APP_API_URL+"/user?id="+payload.id, 
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        }).then((res) => {
          return res.data;
        })
        
      },
      updateUser( {dispatch} , payload) {
        return axios.patch(process.env.VUE_APP_API_URL+"/user",
        payload,
        { 
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        })
        .then((res) => {
          console.log(res);
          dispatch("getUsers");
          return res;
        })
        .catch((error) => {
          return error.response
        })
      }
    },
    getters: {},
  });