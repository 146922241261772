import axios from "axios";
export default({
    state: {
        bookings: [],
        bookingMsgs: [],
        currentBooking: null
    },
    mutations: {
      SET_Bookings(state, payload) {
        state.bookings = payload;
      }  
    },
    actions: {  
      getBookings({ commit }) {
        return axios.get(process.env.VUE_APP_API_URL+"/service/booking/list",
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        }
        )
        .then((res) => {
            console.log(res.data)
            commit("SET_Bookings", res.data);
            return res.data;
        })
      },
      getBookingsByService(_, payload) {
        return axios.get(process.env.VUE_APP_API_URL+"/service/booking/id?service_id="+payload.id,
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        }
        )
        .then((res) => {
            console.log(res.data)
            //commit("SET_ORDERS", res.data);
            return res.data;
        })
      },
      getBookingsByUser({ commit }, payload) {
        return axios.get(process.env.VUE_APP_API_URL+"/service/booking/user?user_id="+payload.userid,
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        }
        )
        .then((res) => {
            console.log(res.data)
            commit("SET_Bookings", res.data);
            return res.data;
        })
      },
      deleteBooking(_,payload){
        return axios.delete(process.env.VUE_APP_API_URL+"/service/booking?id="+payload.id,
        payload, 
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        }).then((res) => {
          return res.data;
        })
        
      },
      getBooking(_, payload) {
        return axios.get(process.env.VUE_APP_API_URL+"/service/booking?id="+payload.id,
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        }
        )
        .then((res) => {
            console.log(res.data);
            return res.data;
        })
      },
      saveBooking( _ , payload) {
        return axios.post(process.env.VUE_APP_API_URL+"/service/booking",
        payload,
        { 
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        })
        .then((res) => {
            console.log(res);
            return res;
        })
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            //console.log(error.response.data);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            return error.response;
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
      
        });
      },
      changeBooking( _ , payload) {
        return axios.patch(process.env.VUE_APP_API_URL+"/service/booking",
        payload,
        { 
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        })
        .then((res) => {
            console.log(res);
            return res;
        })
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            //console.log(error.response.data);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            return error.response;
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
        });
      },
    },
    getters: {
      bookingById: state => payload => state.Bookings.find(o => o.id === payload.id),
    },
  });