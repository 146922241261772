import axios from "axios";
export default({
    state: {
      //isAuthenticated: false
    },
    mutations: {
      SET_DEPARTEMENTS(state, payload) {
        state.departements = payload;
      }  
    },
    actions: {  
      getDepartement(_, payload) {
        return axios.get(process.env.VUE_APP_API_URL+"/department?id="+payload,
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        }
        )
        .then((res) => {
            //console.log(res.data);
            return res.data;
        })
      },

      getDepartements({ commit }) {
        return axios.get(process.env.VUE_APP_API_URL+"/department/list",
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        }
        )
        .then((res) => {
            //console.log(res.data)
            commit("SET_DEPARTEMENTS", res.data);
            return res.data;
        })
      },

      //delete method
      deleteDepartment(_,payload){
        //console.log(payload);
        return axios.delete(process.env.VUE_APP_API_URL+"/department?id="+payload.id,
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        }).then((res) => {
          //dispatch("getDepartements");
          return res.data;
        })
        
      },

      saveDepartement( {dispatch} , payload) {
        return axios.post(process.env.VUE_APP_API_URL+"/department",
        payload,
        { 
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        })
        .then((res) => {
          dispatch("getDepartements");
          return res;
        })
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            //console.log(error.response.data);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            return error.response;
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }

        });
      },

      updateDepartement( {dispatch} , payload) {
        return axios.patch(process.env.VUE_APP_API_URL+"/department",
        payload,
        { 
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        })
        .then((res) => {
          console.log(res);
          dispatch("getDepartements");
          return res;
        })
        .catch((error) => {
          return error.response
        })
      }
    },
    getters: {},
  });