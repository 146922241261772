import axios from "axios";
var qs = require('qs');
export default ({
  state: {
    calendars: []
  },
  mutations: {
    SET_CALENDARS(state, payload) {
      state.calendars = payload;
    }
  },
  actions: {
    getCalendars({ commit }) {
      return axios.get(process.env.VUE_APP_API_URL+"/calendar/template/list",
        {
          headers: { 'Authorization': "Bearer " + localStorage.getItem('authToken'), 'Accept': 'application/json' }
        }
      )
        .then((res) => {
          //console.log(res.data)
          commit("SET_CALENDARS", res.data.calendarTemplates);
          return res.data.calendarTemplates;
        })
    },
    getCalendarsByUser({ commit }, payload) {
      return axios.get(process.env.VUE_APP_API_URL+"/calendar/template/list/user?user_id="+payload,
        {
          headers: { 'Authorization': "Bearer " + localStorage.getItem('authToken'), 'Accept': 'application/json' }
        }
      )
        .then((res) => {
          //console.log(res.data)
          commit("SET_CALENDARS", res.data.calendarTemplates);
          return res.data.calendarTemplates;
        })
    },
    getCalendar(_, payload) {
      return axios.get(process.env.VUE_APP_API_URL+"/calendar/template?id="+payload,
        {
          headers: { 'Authorization': "Bearer " + localStorage.getItem('authToken'), 'Accept': 'application/json' }
        }
      )
        .then((res) => {
          //console.log(res.data)
          return res.data.calendarTemplates;
        })
    },
    saveCalendar({ dispatch }, payload) {
      let data = qs.stringify(payload);
      return axios.post(process.env.VUE_APP_API_URL+"/calendar/template",
        data,
        {
          headers: { 'Authorization': "Bearer " + localStorage.getItem('authToken'), 'Accept': 'application/json' }
        })
        .then((res) => {
          dispatch("getCalendarsByUser", payload.user_id);
          return res.data;
        })
        .catch((error) => {
          return error.response
        })
    },
    deleteCalendar(_,payload){
      console.log(payload);
      return axios.delete(process.env.VUE_APP_API_URL+"/calendar?id="+payload.id,
      payload, 
      {
        headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
      }).then((res) => {
        return res.data;
      })
    },
    updateCalendar( {dispatch} , payload) {
      return axios.patch(process.env.VUE_APP_API_URL+"/calendar/template",
      payload,
      { 
        headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
      })
      .then((res) => {
        console.log(res.data);
        dispatch("getCalendarsByUser", payload.user_id);
        return res.data;
      })
      .catch((error) => {
        return error.response
      })
    }
  },
  getters: {},
});