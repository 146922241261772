import axios from "axios";
export default({
    state: {},
    mutations: {},
    actions: {  
      searchItems(_, payload) {
        console.log(payload);
        let kwString, scoreString, locString, minString, maxString, catString  = "";
        scoreString = "&fitness_score="+payload.score;
        minString = "&min_price="+payload.min;
        maxString = "&max_price="+payload.max;
        if(payload.cat !== "all"){
          catString += "&categories[0]="+payload.cat;
        }
        if(payload.location !== 0){
          [payload.location].forEach((loc, i) => {
            locString += "&locations["+i+"]="+loc;
          });
        }
        if(payload.search !== ""){
          kwString = "keyword="+payload.search;
        }
        return axios.get(process.env.VUE_APP_API_URL+"/filter?"+kwString+catString+scoreString+minString+maxString+locString,
        { 
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        })
        .then((res) => {
            console.log(res.data)
            return res.data;
        })
      },
    },
    getters: {},
  });