import Vue from "vue";
import Vuex from "vuex";
import settingModule from "./Modules/setting-module";
import authModule from "./Modules/auth-module";
import deptModule from "./Modules/dept-module";
import configModule from "./Modules/config-module";
import resourceModule from "./Modules/resource-module";
import calendarModule from "./Modules/calendar-module";
import serviceModule from "./Modules/service-module";
import orderModule from "./Modules/order-module";
import bookingModule from "./Modules/booking-module";
import inspectorModule from "./Modules/inspector-module";
import jobsModule from "./Modules/jobs-module";
import searchModule from "./Modules/search-module";
import uploadModule from "./Modules/upload-module";
Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    setting: settingModule,
    auth: authModule,
    departement: deptModule,
    resource: resourceModule,
    order: orderModule,
    config: configModule,
    calendar: calendarModule,
    service: serviceModule,
    booking: bookingModule,
    inspector: inspectorModule,
    job: jobsModule,
    search: searchModule,
    upload: uploadModule
  }
});