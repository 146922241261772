import axios from "axios";
export default({
    state: {
      services: []
    },
    mutations: {
      SET_SERVICES(state, payload) {
        state.services = payload;
      }  
    },
    actions: {  
      getServices({ commit }) {
        return axios.get(process.env.VUE_APP_API_URL+"/service/list",
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        }
        )
        .then((res) => {
            //console.log(res.data)
            commit("SET_SERVICES", res.data.services);
            return res.data.services;
        })
      },
      getService(_, payload) {
        return axios.get(process.env.VUE_APP_API_URL+"/service?id="+payload.id,
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        }
        )
        .then((res) => {
            //console.log(res.data)
            return res.data.services;
        })
      },
      getServicesByUser({ commit }, payload) {
        return axios.get(process.env.VUE_APP_API_URL+"/service/list/user?user_id="+payload.id,
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        }
        )
        .then((res) => {
            //console.log(res.data)
            commit("SET_SERVICES", res.data.services);
            return res.data.services;
        })
      },
      deleteService(_,payload){
        //console.log(payload);
        return axios.delete(process.env.VUE_APP_API_URL+"/service?id="+payload.id,
        payload, 
        {
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        }).then((res) => {
          return res.data;
        })
        
      },
      saveService( {dispatch} , payload) {
        return axios.post(process.env.VUE_APP_API_URL+"/service",
        payload,
        { 
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        })
        .then((res) => {
            dispatch("getServices", payload.id);
            //console.log(res);
            return res.data;
        })
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            //console.log(error.response.data);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            return error.response;
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
      
        });
      },
      updateService( {dispatch} , payload) {
        return axios.patch(process.env.VUE_APP_API_URL+"/service",
        payload,
        { 
          headers: { 'Authorization': "Bearer " +localStorage.getItem('authToken'), 'Accept': 'application/json' }
        })
        .then((res) => {
          //console.log(res);
          dispatch("getServices");
          return res;
        })
        .catch((error) => {
          return error.response
        })
      }
    },
    getters: {},
  });