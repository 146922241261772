import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,

  routes: [
    {
      path: "/",
      name: "Home",
      redirect: "landing",
      component: () => import("@/layouts/full-layout/Frontlayout"),
      children: [
        {
          name: "Landing",
          path: "landing",
          component: () => import("@/views/front/Landing"),
        },
        {
          path: "listing/:type/:cat",
          name: "Listing",
          component: () => import("@/views/front/listing"),
        },
        {
          path: "product/:uid/:id",
          name: "Product",
          component: () => import("@/views/front/product-detail"),
        },
        {
          path: "service/:uid/:id",
          name: "Service",
          component: () => import("@/views/front/service-detail"),
        },
      ],
    },
    {
      path: "/auth/",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "Login",
          path: "login",
          component: () => import("@/views/authentication/FullLogin"),
        },
        {
          name: "Register",
          path: "register",
          component: () => import("@/views/authentication/FullRegister"),
        },
      ],
    },
    {
      path: "/dashboard/",
      component: () => import("@/layouts/full-layout/FullLayout"),
      children: [
        {
          name: "Starter",
          path: "starter",
          component: () => import("@/views/dashboard/Starter"),
        },
        {
          name: "Page",
          path: "page",
          component: () => import("@/views/dashboard/Page"),
        }
      ],
    },
    {
      path: "/departments/",
      component: () => import("@/layouts/full-layout/FullLayout"),
      children: [
        {
          name: "Departments",
          path: "/",
          component: () => import("@/views/departments/Departments"),
        },
        {
          name: "AddDepartment",
          path: "add",
          component: () => import("@/views/departments/Add"),
        },
        {
          name: "Update Department",
          path: "update/:id",
          component: () => import("@/views/departments/Update"),
        },
      ],
    },
    {
      path: "/resources/",
      component: () => import("@/layouts/full-layout/FullLayout"),
      children: [
        {
          name: "Resources",
          path: "/",
          component: () => import("@/views/resources/Resources"),
        },
        {
          name: "Create Resource",
          path: "add",
          component: () => import("@/views/resources/Add"),
        },
        {
          name:"Update Resource",
          path:"update/:id",
          component: () => import("@/views/resources/update")
        }
      ],
    },
    {
      path: "/services/",
      component: () => import("@/layouts/full-layout/FullLayout"),
      children: [
        {
          name: "Services",
          path: "/",
          component: () => import("@/views/services/Services"),
        },
        {
          name: "Create Service",
          path: "add",
          component: () => import("@/views/services/Add"),
        },
        {
          name: "Update Service",
          path: "update/:id",
          component: () => import("@/views/services/Update"),
        },
      ],
    },
    {
      path: "/inspectors/",
      component: () => import("@/layouts/full-layout/FullLayout"),
      children: [
        {
          name: "Inspectors",
          path: "/",
          component: () => import("@/views/inspectors/Inspectors"),
        },
        {
          name: "Create Inspector",
          path: "add",
          component: () => import("@/views/inspectors/Add"),
        },
        {
          name: "Update Inspector",
          path: "update/:id",
          component: () => import("@/views/inspectors/update"),
        },
      ],
    },
    {
      path: "/orders/",
      component: () => import("@/layouts/full-layout/FullLayout"),
      children: [
        {
          name: "Orders",
          path: "/",
          component: () => import("@/views/orders/Orders"),
        },
        {
          name: "Create Order",
          path: "add",
          component: () => import("@/views/orders/Add"),
        },
        {
          name: "ViewOrder",
          path: "view/:id",
          component: () => import("@/views/orders/OrderDetail"),
        },
      ],
    },
    {
      path: "/bookings/",
      component: () => import("@/layouts/full-layout/FullLayout"),
      children: [
        {
          name: "Bookings",
          path: "/",
          component: () => import("@/views/bookings/Bookings"),
        },
        {
          name: "Create Booking",
          path: "add",
          component: () => import("@/views/bookings/Add"),
        },
        {
          name: "ViewBooking",
          path: "view/:id",
          component: () => import("@/views/bookings/BookingDetail"),
        },
      ],
    },
    {
      path: "/inspectionjobs/",
      component: () => import("@/layouts/full-layout/FullLayout"),
      children: [
        {
          name: "InspectionJobs",
          path: "/",
          component: () => import("@/views/inspectionjobs/InspectionJobs"),
        },
        {
          name: "Update Inspection Job",
          path: "update/:id",
          component: () => import("@/views/inspectionjobs/Update"),
        },
      ],
    },
    {
      path: "/users/",
      component: () => import("@/layouts/full-layout/FullLayout"),
      children: [
        {
          name: "Users",
          path: "/",
          component: () => import("@/views/users/Users"),
        },
        {
          name: "Create User",
          path: "add",
          component: () => import("@/views/users/Add"),
        },
        {
          name: "profile",
          path: "profile",
          component: () => import("@/views/users/Profile"),
        },
        {
          name: "updateUser",
          path: "update/:id",
          component: () => import("@/views/users/Update"),
        }
      ],
    },
    {
      path: "/global-settings/",
      component: () => import("@/layouts/full-layout/FullLayout"),
      children: [
        {
          path: "/",
          name: "GlobalSettings",
          component: () => import("@/views/global-settings/globalSettings"),
        }
      ]
    },
    {
      path: "/calendars/",
      component: () => import("@/layouts/full-layout/FullLayout"),
      children: [
        {
          name: "Calendars",
          path: "/",
          component: () => import("@/views/Calendars/calendars"),
        },
        {
          name: "AddCalendar",
          path: "add",
          component: () => import("@/views/Calendars/add"),
        },
        {
          name: "UpdateCalendar",
          path: "update/:id",
          component: ()=>import("@/views/Calendars/update")
        },
      ],
    },
    {
      path: "*",
      component: () => import("@/views/authentication/Error"),
    },
  ],
});

import NProgress from "nprogress";

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start(800);
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
